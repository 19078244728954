import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function TermsAndConditions() {
    return (
        <>
            <Row>
                <h2 className={s.title}>წესები და პირობები</h2>
            </Row>
        </>
    );
}
