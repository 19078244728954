import { Link } from "react-router-dom";
import ProductDetails from "../../components/ProductDetails";
import { Table } from "react-bootstrap";

export default function IndoorCamE220() {
    return (
        <div>
            <ProductDetails
                id="IndoorCam-E220"
                title={"eufy IndoorCam E220 (WiFi)"}
                price={"189₾"}
                imageUrl={"/images/eufy-indoorcam-e220-original.webp"}
                headline={
                    "შიდა უსაფრთხოების კამერა უზრუნველყოფს 360°-იან მონიტორინგს, უწყვეტი ჩაწერით."
                }
                shortDescription={[
                    {
                        title: "მნიშვნელოვანი მოვლენები:",
                        description:
                            "კამერაში ჩაშენებული მოძრაობის დეტექტორი ადამიანის დაფიქსირებისას ჩაიწერს აქტუალურ კადრებს, საეჭვო აქტივობისას შეგატყობინებს აპლიკაციით და ჩაირთვება სიგნალიზაცია.",
                    },
                    {
                        title: "გასაღები დეტალებშია:",
                        description:
                            "ნებისმიერ დროს, ნებისმიერი ადგილიდან პირდაპირ რეჟიმში აპლიკაციით ნახე ყველა მნიშვნელოვანი მოვლენის 2K მკაფიო გამოსახულება.",
                    },
                    {
                        title: "მიყვება მოძრაობას:",
                        description:
                            "მოძრაობის დაფიქსირებისას კამერის ობიექტივი ავტომატურად მიყვება მოძრავ ობიექტს. კამერას აქვს 125° გრადუსიანი ხედვის არეალი, შეუძლია 360° გრადუსით ჰორიზონტალურად და 96° გრადუსით ვერტიკალურად შებრუნება.",
                    },
                    {
                        title: "დაელაპარაკე კამერით:",
                        description:
                            "დაელაპარაკე ოთახში მყოფებს რეალურ დროში, კამერაში ჩაშენებული ორმხრივი აუდიოკავშირით (მიკროფონი და დინამიკი).",
                    },
                    {
                        title: "ღამის ხედვა:",
                        description:
                            "ინფრაწითელი ღამის ხედვის ტექნოლოგია დაგეხმარება დაინახო და გაარჩიო გამოსახულება ღამითაც.",
                    },
                    {
                        title: (
                            <Link
                                className="underline"
                                to="https://www.eufy.com/privacy-commitment"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                კონფიდენციალური და დაშიფრული:
                            </Link>
                        ),
                        description:
                            "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
                    },
                    {
                        title: (
                            <Link
                                className="underline"
                                to="https://youtu.be/S0j_ct7zUgk?si=uk542mc_3C-0NUxV&t=34"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ვიდეო განხილვა
                            </Link>
                        ),
                    },
                ]}
                specs={
                    <Table striped="columns" className="mt-5">
                        <tbody>
                            <tr>
                                <td>რეზოლუცია</td>
                                <td>2K (2560 x 1440)</td>
                            </tr>
                            <tr>
                                <td>უწყვეტი ჩაწერა</td>
                                <td>კი</td>
                            </tr>
                            <tr>
                                <td>ხედვის არეალი</td>
                                <td>125°</td>
                            </tr>
                            <tr>
                                <td>კამერის შებრუნება</td>
                                <td>
                                    <p>360° ჰორიზონტალურად</p>
                                    <p>96° ვერტიკალურად</p>
                                </td>
                            </tr>
                            <tr>
                                <td>ღამის ხედვა</td>
                                <td>კი (ინფრაწითელი ღამის ხედვა)</td>
                            </tr>
                            <tr>
                                <td>მოძრაობის დეტექტორი</td>
                                <td>
                                    კი (ნებისმიერი მოძრაობის; ადამიანის; ბავშვის
                                    ტირილის; შინაური ცხოველის)
                                </td>
                            </tr>
                            <tr>
                                <td>მყისიერი შეტყობინება ტელეფონზე</td>
                                <td>კი (მოძრაობის დაფიქსირებისას)</td>
                            </tr>
                            <tr>
                                <td>განგაშის სირენა / სიგნალიზაცია</td>
                                <td>კი (მოძრაობის დაფიქსირებისას)</td>
                            </tr>
                            <tr>
                                <td>მოძრავ ობიექტზე მიყოლა</td>
                                <td>კი</td>
                            </tr>
                            <tr>
                                <td>ორმხრივი აუდიო კომუნიკაცია</td>
                                <td>კი (ჩაშენებული მიკროფონი და დინამიკი)</td>
                            </tr>
                            <tr>
                                <td>მეხსიერება</td>
                                <td>
                                    არ მოყვება (მაქსიმუმ 128 GB microSD ჩიპი,
                                    იყიდება ცალკე)
                                </td>
                            </tr>
                            <tr>
                                <td>აპლიკაცია</td>
                                <td>
                                    კი (ლაივ კადრების ან ჩანაწერების ყურება
                                    ნებისმიერ დროს, ნებისმიერი ადგილიდან)
                                </td>
                            </tr>
                            <tr>
                                <td>ჭკვიანი ინტეგრაციები</td>
                                <td>Google Assistant, Alexa, Homekit</td>
                            </tr>
                            <tr>
                                <td>ყველა ამინდში გამოყენებისთვის</td>
                                <td>არა (მხოლოდ შიდა სივრცეში)</td>
                            </tr>
                            <tr>
                                <td>Wi-Fi კავშირი</td>
                                <td>2.4GHz</td>
                            </tr>
                            <tr>
                                <td>კვების წყარო</td>
                                <td>5V/2A USB კაბელი და ადაპტერი</td>
                            </tr>
                            <tr>
                                <td>რა არის ყუთში</td>
                                <td>
                                    <li>კამერა</li>
                                    <li>USB ადაპტერი</li>
                                    <li>USB კაბელი (2 მეტრი)</li>
                                    <li>კამერის ჭერზე სამაგრი</li>
                                    <li>ბეტონში სამაგრი ჭანჭიკები</li>
                                    <li>ინსტრუქცია</li>
                                </td>
                            </tr>
                            <tr>
                                <td>გარანტია</td>
                                <td>12 თვე</td>
                            </tr>
                        </tbody>
                    </Table>
                }
            />
        </div>
    );
}
// https://support.eufy.com/s/article/Differences-Between-eufy-Indoor-Cams
// https://support.eufy.com/s/article/Introducing-eufy-Indoor-Cam-2K-Pan-Tilt
