import {
    Routes,
    Route,
    Outlet,
    useNavigate,
    useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import NotFound from "./components/NotFound";
import IndoorCamE220 from "./pages/Products/IndoorCamE220";
import SoloCamS220 from "./pages/Products/SoloCamS220";
import { Container } from "react-bootstrap";
import Order from "./pages/Order";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Warranty from "./pages/Policies/Warranty";
import Delivery from "./pages/Policies/Delivery";
import Privacy from "./pages/Policies/Privacy";
import TermsAndConditions from "./pages/Policies/TermsAndConditions";

export default function App() {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        try {
            if (i18n.language === "en") {
                setTimeout(() => {
                    if (!window.location.href.includes("localhost:3000")) {
                        i18n.changeLanguage("ka");
                        if (pathname.includes("/en")) {
                            navigate(pathname.replace("/en", ""));
                        }
                    }
                }, 5_000);
            }
        } catch (error) {
            console.log(error);
        }
    }, [i18n]);

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="IndoorCam-E220" element={<IndoorCamE220 />} />
                <Route path="SoloCam-S220" element={<SoloCamS220 />} />
                <Route path="order" element={<Order />} />
                <Route path="order/:product" element={<Order />} />
                <Route path="policies/warranty" element={<Warranty />} />
                <Route path="policies/delivery" element={<Delivery />} />
                <Route path="policies/privacy" element={<Privacy />} />
                <Route
                    path="policies/terms-and-conditions"
                    element={<TermsAndConditions />}
                />

                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/:lang" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="IndoorCam-E220" element={<IndoorCamE220 />} />
                <Route path="SoloCam-S220" element={<SoloCamS220 />} />
                <Route path="order" element={<Order />} />
                <Route path="order/:product" element={<Order />} />
                <Route path="policies/warranty" element={<Warranty />} />
                <Route path="policies/delivery" element={<Delivery />} />
                <Route path="policies/privacy" element={<Privacy />} />
                <Route
                    path="policies/terms-and-conditions"
                    element={<TermsAndConditions />}
                />

                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

function Layout() {
    return (
        <div>
            <Header />
            <div style={{ minHeight: "48px" }}></div>
            <Container className="mt-5">
                <Outlet />
            </Container>
            <Footer />
        </div>
    );
}
