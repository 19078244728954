import { Link } from "react-router-dom";
import ProductDetails from "../../components/ProductDetails";
import { Table } from "react-bootstrap";

export default function SoloCamS220() {
    return (
        <div>
            <ProductDetails
                id="SoloCam-S220"
                title={"eufy SoloCam S220 (WiFi)"}
                price={"399₾"}
                imageUrl={"/images/eufy-solocam-s220-original.webp"}
                headline={
                    "გარე სამეთვალყურეო კამერა აღჭურვილია მზის პანელით და გადაიღებს მნიშვნელოვან მოვლენებს ნებისმიერ ამინდში (IP67)."
                }
                shortDescription={[
                    {
                        title: "ჩაშენებული მზის პანელი:",
                        description:
                            "მზის პანელი უზრუნველყოფს ბატარეის ყოველდღიურ დამუხტვას, რის შედეგადაც კამერა უსასრულოდ იმუშავებს სრულად უკაბელოდ.",
                    },
                    {
                        title: "მნიშვნელოვანი მოვლენები:",
                        description:
                            "კამერაში ჩაშენებული მოძრაობის დეტექტორი ადამიანის დაფიქსირებისას ჩაიწერს აქტუალურ კადრებს, საეჭვო აქტივობისას შეგატყობინებს აპლიკაციით და ჩაირთვება სიგნალიზაცია.",
                    },
                    {
                        title: "გასაღები დეტალებშია:",
                        description:
                            "ნებისმიერ დროს, ნებისმიერი ადგილიდან პირდაპირ რეჟიმში აპლიკაციით ნახე ყველა მნიშვნელოვანი მოვლენის 2K მკაფიო გამოსახულება, 135° გრადუსიანი ფართო ხედვის არეალით.",
                    },
                    {
                        title: "დაელაპარაკე კამერით:",
                        description:
                            "დაელაპარაკე ოთახში მყოფებს რეალურ დროში, კამერაში ჩაშენებული ორმხრივი აუდიოკავშირით (მიკროფონი და დინამიკი).",
                    },
                    {
                        title: "ღამის ხედვა:",
                        description:
                            "ინფრაწითელი ღამის ხედვის f/1.6-იანი დიაფრაგმიანი ტექნოლოგია დაგეხმარება მკვეთრად დაინახო და გაარჩიო გამოსახულება ღამითაც, ისევე როგორც დღისით.",
                    },
                    {
                        title: (
                            <Link
                                className="underline"
                                to="https://www.eufy.com/privacy-commitment"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                კონფიდენციალური და დაშიფრული:
                            </Link>
                        ),
                        description:
                            "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
                    },
                    {
                        title: (
                            <Link
                                className="underline"
                                to="https://youtu.be/KiSxCBObplU?feature=shared&t=59"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ვიდეო განხილვა
                            </Link>
                        ),
                    },
                ]}
                specs={
                    <Table striped="columns" className="mt-5">
                        <tbody>
                            <tr>
                                <td>ჩაშენებული მზის პანელი</td>
                                <td>კი</td>
                            </tr>
                            <tr>
                                <td>რეზოლუცია</td>
                                <td>2K (2560 x 1440)</td>
                            </tr>
                            <tr>
                                <td>ჩაშენებული ბატარეა</td>
                                <td>
                                    კი - 6500 mAh (მზის გარეშე 3 თვიანი მუშაობის
                                    რესურსი)
                                </td>
                            </tr>
                            <tr>
                                <td>მოძრაობის დაფიქსირებისას ჩაწერა</td>
                                <td>კი</td>
                            </tr>
                            <tr>
                                <td>უწყვეტი ჩაწერა</td>
                                <td>მოძრაობის დაფიქსირებისას</td>
                            </tr>
                            <tr>
                                <td>ფართო ხედვის არეალი</td>
                                <td>135°</td>
                            </tr>
                            <tr>
                                <td>ღამის ხედვა</td>
                                <td>
                                    კი (ინფრაწითელი ღამის ხედვა f/1.6-იანი
                                    დიაფრაგმით)
                                </td>
                            </tr>
                            <tr>
                                <td>მოძრაობის დეტექტორი</td>
                                <td>კი (ნებისმიერი მოძრაობის; ადამიანის)</td>
                            </tr>
                            <tr>
                                <td>მყისიერი შეტყობინება ტელეფონზე</td>
                                <td>კი (მოძრაობის დაფიქსირებისას)</td>
                            </tr>
                            <tr>
                                <td>განგაშის სირენა / სიგნალიზაცია</td>
                                <td>კი (მოძრაობის დაფიქსირებისას)</td>
                            </tr>
                            <tr>
                                <td>ორმხრივი აუდიო კომუნიკაცია</td>
                                <td>კი (ჩაშენებული მიკროფონი და დინამიკი)</td>
                            </tr>
                            <tr>
                                <td>მეხსიერება</td>
                                <td>კი (ჩაშენებული 8 GB EMMC)</td>
                            </tr>
                            <tr>
                                <td>აპლიკაცია</td>
                                <td>
                                    კი (ლაივ კადრების ან ჩანაწერების ყურება
                                    ნებისმიერ დროს, ნებისმიერი ადგილიდან)
                                </td>
                            </tr>
                            <tr>
                                <td>ჭკვიანი ინტეგრაციები</td>
                                <td>Google Assistant, Alexa</td>
                            </tr>
                            <tr>
                                <td>ყველა ამინდში გამოყენებისთვის</td>
                                <td>კი (IP67 წყალგამძლე)</td>
                            </tr>
                            <tr>
                                <td>Wi-Fi კავშირი</td>
                                <td>2.4GHz</td>
                            </tr>
                            <tr>
                                <td>კვების წყარო</td>
                                <td>
                                    ჩაშენებული მზის პანელი (სათადარიგოდ
                                    დასამუხტად მოყვება USB-C კაბელი)
                                </td>
                            </tr>
                            <tr>
                                <td>რა არის ყუთში</td>
                                <td>
                                    <li>კამერა</li>
                                    <li>USB-C კაბელი </li>
                                    <li>კამერის კედელზე სამაგრი</li>
                                    <li>ბეტონში სამაგრი ჭანჭიკები</li>
                                    <li>ინსტრუქცია</li>
                                </td>
                            </tr>
                            <tr>
                                <td>გარანტია</td>
                                <td>12 თვე</td>
                            </tr>
                        </tbody>
                    </Table>
                }
            />
        </div>
    );
}
// https://www.eufy.com/products/t8134121?variant=42389580873914
// https://www.eufy.com/security-s220-solocam
