import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import translations from "./translations.json";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: translations,
        fallbackLng: "ka",
        supportedLngs: ["en", "ka"],
        detection: {
            order: ["path", "querystring"],
            lookupFromPathIndex: 0,
            lookupQuerystring: "lang",
        },
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
            <ScrollToTop />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
