import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import s from "./styles.module.css";

export default function Header() {
    const { t } = useTranslation();
    const { lang } = useParams();
    const navigate = useNavigate();
    const offCanvasRef = useRef();
    const closeOffCanvas = () => offCanvasRef.current.backdrop.click();

    return (
        <div className={s.header}>
            <Navbar expand={false} collapseOnSelect className={s.navbar}>
                <Container className={s.navContainer}>
                    <div className={s.navContainer}>
                        <Link to={`${lang ? `/${lang}` : "/"}`}>
                            <img
                                className={s.logo}
                                src="/images/logo.svg"
                                alt="eufy Georgia logo"
                            />
                        </Link>

                        <ul>
                            {/* <li>
                                <Nav.Link
                                    to="/about-us"
                                    as={NavLink}
                                    // onClick={() => closeOffCanvas()}
                                >
                                    ჩვენ შესახებ
                                </Nav.Link>
                            </li> */}
                            <li>
                                <Nav.Link
                                    to={`${
                                        lang ? `/${lang}` : ""
                                    }/IndoorCam-E220`}
                                    as={NavLink}
                                    // onClick={() => closeOffCanvas()}
                                >
                                    {t("header.indoor-cam")}
                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link
                                    to={`${
                                        lang ? `/${lang}` : ""
                                    }/SoloCam-S220`}
                                    as={NavLink}
                                    // onClick={() => closeOffCanvas()}
                                >
                                    {t("header.outdoor-cam")}
                                </Nav.Link>
                            </li>
                        </ul>
                    </div>
                    {/* </div>
                    <div className="flex"> */}
                    {/* <nav className={s.navbarMain}>
                            
                        </nav> */}
                    {/* </div> */}

                    {/* <div>
                        <Button
                            className="d-none d-sm-inline"
                            variant="primary"
                            size="md"
                            onClick={() => {
                                navigate("/order");
                            }}
                            style={{
                                // backgroundColor: COLORS.PRIMARY.TURQOISE,
                                // borderColor: COLORS.PRIMARY.TURQOISE,
                                marginRight: 15,
                            }}
                        >
                            {t("header.order")}
                        </Button>
                        <Navbar.Toggle
                            aria-controls={`offcanvasNavbar-expand-false`}
                            style={
                                {
                                    // borderColor: COLORS.PRIMARY.BLUE,
                                }
                            }
                        />
                    </div> */}

                    <Navbar.Offcanvas
                        ref={offCanvasRef}
                        id={`offcanvasNavbar-expand-false`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Button
                                variant="primary"
                                size="md"
                                onClick={() => {
                                    navigate("/order");
                                    closeOffCanvas();
                                }}
                                style={{
                                    // backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    // borderColor: COLORS.PRIMARY.TURQOISE,
                                    marginRight: 15,
                                }}
                            >
                                {t("header.order")}
                            </Button>
                            {/* <Stack direction="horizontal" gap={4}>
                        <Nav.Link
                            className={[
                                "language",
                                i18n.language === "ka" ? "active" : null,
                            ]}
                            onClick={() => i18n.changeLanguage("ka")}
                        >
                            ქართული
                        </Nav.Link>
                        <Nav.Link
                            className={[
                                "language",
                                i18n.language === "en" ? "active" : null,
                            ]}
                            onClick={() => i18n.changeLanguage("en")}
                        >
                            English
                        </Nav.Link>
                    </Stack> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Nav.Link
                                    to="/"
                                    as={NavLink}
                                    onClick={() => closeOffCanvas()}
                                >
                                    {t("header.home")}
                                </Nav.Link>
                                <Nav.Link
                                    to="/order"
                                    as={NavLink}
                                    onClick={() => closeOffCanvas()}
                                >
                                    {t("header.order")}
                                </Nav.Link>
                                <Nav.Link
                                    to="/orders"
                                    as={NavLink}
                                    onClick={() => closeOffCanvas()}
                                >
                                    {t("header.orders")}
                                </Nav.Link>
                                <hr className="rounded" />
                                <Nav.Link
                                    onClick={() => {
                                        navigate("/");
                                        closeOffCanvas();
                                    }}
                                    style={
                                        {
                                            // color: COLORS.ADDITIONAL.RED,
                                        }
                                    }
                                >
                                    {t("header.logout")}
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}
