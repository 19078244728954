import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function Privacy() {
    return (
        <>
            <Row>
                <h2 className={`${s.title} break-words`}>
                    კონფიდენციალურობის პირობები
                </h2>
            </Row>
            <Row>
                <p>
                    ჩვენი ვებ-გვერდით ვიზიტორების და მომხმარებლების პერსონალური
                    მონაცემები დაცულია მაღალი ხარისხით, საქართველოს
                    კანონმდებლობის შესაბამისად.
                </p>
                <p className="mt-3">
                    ჩვენი ვებ-გვერდით სარგებლობა მიიჩნევა ვიზიტორის მიერ
                    კონფიდენციალურობის პოლიტიკის თაობაზე თანხმობად. შესაბამისად,
                    ვიზიტორი გვანიჭებს უფლებას დავამუშაოთ, შევინახოთ და
                    გამოვიყენოთ ვიზიტორის მიერ მიწოდებული პერსონალური ხასიათის
                    ინფორმაცია. აღნიშნული ინფორმაცია ჩვენ მიერ გამოყენებული
                    იქნება მომსახურების გაწევის მიზნებიდან გამომდინარე მოქმედი
                    კანონმდებლობის გათვალისწინებით.
                </p>
                <p className="mt-3">
                    ვიზიტორის მიერ ჩვენი ვებ-გვერდის გამოყენებით აცხადებს
                    თანხმობას ჩვენ მიერ მისი პერსონალური მონაცემებისა და მზა
                    ჩანაწერების (Cookies) შენახვასა და დამუშავებაზე.
                    მომხმარებელი ვალდებულია მოგვაწოდოს სრული და სწორი
                    ინფორმაცია, ცვლილების შემთხვევაში განაახლოს მის მიერ
                    მოწოდებული ინფორმაცია.
                </p>
                <p className="mt-3">
                    შეკვეთის გაკეთებისას მომხმარებლისგან ვიღებთ შემდეგ
                    ინფორმაციას, იმისათვის რომ მოხდეს მიწოდების და საგარანტიო
                    მომსახურების სრულფასოვანი შესრულება:
                </p>
                <ul className={s.unorderedList}>
                    <li className={s.listItem}>სახელი და გვარი</li>
                    <li className={s.listItem}>პირადი ნომერი</li>
                    <li className={s.listItem}>ტელეფონის ნომერი</li>
                    <li className={s.listItem}>
                        ელექტრონული ფოსტა (არასავალდებულო)
                    </li>
                    <li className={s.listItem}>
                        მისამართი (მიწოდების მომსახურების მოთხოვნის შემთხვევაში)
                    </li>
                </ul>
                <p className="mt-3">
                    მომხმარებელს აქვს უფლება ნებისმიერ დროს მოითხოვოს მისი
                    მონაცემების წაშლა, რის საფუძველზეც ჩვენს ხელთ არსებული ყველა
                    მონაცემი წაიშლება. ამ მოთხოვნასთან დაკავშირებით
                    დაგვიკავშირდით ელ. ფოსტაზე: info@eufy,ge
                </p>
            </Row>
        </>
    );
}
