import Container from "react-bootstrap/Container";
import s from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function TermsItems() {
    return (
        <>
            <li>
                <p className={s.secondaryText}>
                    <Link to="/policies/warranty">გარანტია</Link>
                </p>
            </li>
            <li>
                <p className={s.secondaryText}>
                    <Link to="/policies/delivery">მიწოდება</Link>
                </p>
            </li>
            <li>
                <p className={s.secondaryText}>
                    <Link to="/policies/privacy">კონფიდენციალურობა</Link>
                </p>
            </li>
            <li>
                <p className={s.secondaryText}>
                    <Link
                        to="https://www.eufy.com/eu-en/privacy-commitment"
                        target="_blank"
                    >
                        მონაცემების დაცულობა (EN)
                    </Link>
                </p>
            </li>
            {/* <li>
                <p className={s.secondaryText}>
                    <Link to="/policies/terms-and-conditions">
                        წესები და პირობები
                    </Link>
                </p>
            </li> */}
        </>
    );
}

function ContactItems() {
    return (
        <>
            <li>
                <img
                    src="/images/phone.svg"
                    alt="ტელ. ნომერი"
                    className={s.icon}
                />
                <p className={s.iconPrimaryText}>
                    <a href="tel:574180111">574 180 111</a>
                </p>
            </li>
            <li>
                <img
                    src="/images/email.svg"
                    alt="ელ. ფოსტა"
                    className={s.icon}
                />
                <p className={s.iconPrimaryText}>
                    <a href="mailto:info@eufy.ge">info@eufy.ge</a>
                </p>
            </li>
            <li>
                <img
                    src="/images/facebook.svg"
                    alt="Facebook"
                    className={s.icon}
                />
                <p className={s.iconPrimaryText}>
                    <a
                        href="https://www.facebook.com/eufy.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Facebook
                    </a>
                </p>
            </li>
            <li>
                <img
                    src="/images/instagram.svg"
                    alt="Instagram"
                    className={s.icon}
                />
                <p className={s.iconPrimaryText}>
                    <a
                        href="https://www.instagram.com/eufy.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Instagram
                    </a>
                </p>
            </li>
            <li>
                <img src="/images/tiktok.svg" alt="TikTok" className={s.icon} />
                <p className={s.iconPrimaryText}>
                    <a
                        href="https://www.tiktok.com/@eufy.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        TikTok
                    </a>
                </p>
            </li>
        </>
    );
}

export default function Footer() {
    const { t } = useTranslation();
    return (
        <footer className={s.footer}>
            <Container>
                <Row>
                    <Col className="mt-2" sm={5}>
                        <div className={s.sectionTitle}>
                            {t("footer.contact")}
                        </div>
                        <ul className={s.sectionBody}>
                            <div>
                                <ContactItems />
                            </div>
                        </ul>
                    </Col>
                    <Col className="mt-2" sm={5}>
                        <div className={s.sectionTitle}>პირობები</div>
                        <ul className={s.sectionBody}>
                            <div>
                                <TermsItems />
                            </div>
                        </ul>
                    </Col>
                </Row>

                <hr className="mt-3" />

                <Row>
                    <Col className="mt-2" md={5}>
                        <div className={s.sectionTitle}>
                            {t("footer.download-app")}
                        </div>
                        <ul className={s.sectionBody}>
                            <div className={s.download}>
                                <img
                                    src="/images/eufy-security-app.png"
                                    alt="Eufy Security App"
                                    className={s.appIcon}
                                />
                                <div>
                                    <a
                                        href="https://apps.apple.com/us/app/eufy-security/id1424956516"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/images/app-store.svg"
                                            alt="App Store"
                                            className={s.downloadIcon}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.oceanwing.battery.cam"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/images/google-play.svg"
                                            alt="Google Play"
                                            className={s.downloadIcon}
                                        />
                                    </a>
                                </div>
                            </div>
                        </ul>
                    </Col>
                    <Col className="mt-2" md={5}>
                        <div className={s.certifications}>
                            <Link
                                to="https://www.eufy.com/eu-en/privacy-commitment"
                                target="_blank"
                            >
                                <img
                                    src="/images/ISO-27001-certified.webp"
                                    alt="ISO 27001 Certified"
                                    className={s.appIcon}
                                />
                            </Link>

                            <Link
                                to="https://www.eufy.com/eu-en/privacy-commitment"
                                target="_blank"
                            >
                                <img
                                    src="/images/ISO-27701-certified.webp"
                                    alt="ISO 27701 Certified"
                                    className={s.appIcon}
                                />
                            </Link>
                            <Link
                                to="https://www.eufy.com/eu-en/privacy-commitment"
                                target="_blank"
                            >
                                <img
                                    src="/images/GDPR-compliant.webp"
                                    alt="GDPR Compliant"
                                    className={s.appIcon}
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>

                <div className={s.keywords}>
                    იუფი საქართველო, პრემიუმ ხარისხის უსაფრთხოების ჭკვიანი
                    სამეთვალყურეო კამერები, უსაფრთხოების კამერა, სამეთვალყურეო
                    კამერა, უსაფრთხოების კამერები, ჭკვიანი უსაფრთხოების კამერა,
                    სათვალთვალო სისტემები, სახლის უსაფრთხოება, უკაბელო
                    უსაფრთხოების კამერები, Wi-Fi კამერა, 2K რეზოლუციის კამერა,
                    4MP კამერები, სახლის მეთვალყურეობა, გარე უსაფრთხოების
                    კამერები, შიდა უსაფრთხოების კამერები, უსაფრთხოების კამერის
                    სისტემები, HD უსაფრთხოების კამერები, ღამის ხედვის კამერები,
                    მოძრაობის ამოცნობის კამერები, უსაფრთხოების კამერის მონტაჟი,
                    დისტანციური ხედვის კამერები, ძიძის კამერები, უსაფრთხოების
                    გადაწყვეტილებები, ბიზნეს უსაფრთხოების კამერები, 24/7
                    მეთვალყურეობა, ჭკვიანი უსაფრთხოების კამერები, kamerebi,
                    Security Cameras, Surveillance Systems, Home Security,
                    Wireless Security Cameras, Wi-Fi Security Cameras, Video
                    Surveillance, Home Surveillance, Outdoor Security Cameras,
                    Indoor Security Cameras, Security Camera Systems, HD
                    Security Cameras, 2K Security Cameras, Night Vision Cameras,
                    Motion Detection Cameras, Security Camera Installation,
                    Remote Viewing Cameras, Nanny Cams, Security Solutions, DIY
                    Security Systems, Business Security Cameras, 24/7
                    Surveillance, Smart Security Cameras
                </div>
            </Container>
        </footer>
    );
}
