import { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import s from "./styles.module.css";
import { Link } from "react-router-dom";
import CustomModal from "../Modal";

export default function ProductDetails({
    id,
    title,
    price,
    imageUrl,
    headline,
    shortDescription,
    specs,
}) {
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <Row>
                <Col md={6}>
                    <picture
                        className="mb-5 block overflow-hidden"
                        onClick={() => window.scrollTo(0, 500)}
                    >
                        <img
                            src={imageUrl}
                            loading="lazy"
                            alt={title}
                            className="w-full"
                        />
                    </picture>
                </Col>
                <Col>
                    <h1 className={`${s.title} mb-2`}>{title}</h1>
                    <div className={`${s.price} mb-3`}>
                        {price}{" "}
                        <Link className="brand-button" to={`/order/${id}`}>
                            ყიდვა
                        </Link>
                        {/* <Button
                            size="md"
                            variant="none"
                            className="brand-button"
                            // onClick={() => setShowModal(true)}
                        >
                            ყიდვა
                        </Button> */}
                    </div>
                    <p>{headline}</p>
                    <ul className={`${s.unorderedList} ml-2`}>
                        {shortDescription.map((item, index) => (
                            <li className={s.listItem} key={index}>
                                <strong className={s.strong}>
                                    {item.title}
                                </strong>{" "}
                                {item.description}
                            </li>
                        ))}
                    </ul>
                    {/* <div className={s.buyButton}></div> */}
                    {/* <div className={`${s.buyContainer} mb-3`}>
                        <input defaultValue={1} min={1} type="number" />
                        <button>ყიდვა</button>
                    </div> */}
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col sm={12} md={10} lg={8}>
                    {specs}
                </Col>
                <Col></Col>
            </Row>
            {/* <CustomModal showModal={showModal} setShowModal={setShowModal} /> */}
        </div>
    );
}
