import { Link } from "react-router-dom";
import s from "./styles.module.css";
import { useTranslation } from "react-i18next";

export default function Showcase({
    theme,
    title,
    description,
    imageUrl,
    detailsUrl,
    backdrop,
}) {
    const { t } = useTranslation();
    return (
        <div
            className={`${s.container} relative w-full overflow-hidden rounded-xl transition col-span-3`}
        >
            <picture className="block overflow-hidden">
                <img
                    src={imageUrl}
                    loading="lazy"
                    alt={title}
                    className="w-full"
                />
            </picture>
            <div className="absolute top-[40px] left-0 box-border w-full px-5 text-center min-xl:bottom-14">
                <Link to={detailsUrl} className="!no-underline">
                    <h4
                        className={`${s.title} ${
                            theme === "blue" ? s.blueText : undefined
                        }`}
                    >
                        {title}
                    </h4>
                </Link>
                <p
                    className={`${s.text} ${
                        backdrop ? s.backdrop : undefined
                    } rounded-xl`}
                >
                    {description}
                </p>
            </div>
            <div className="absolute bottom-[40px] left-0 box-border w-full px-5 text-center min-xl:bottom-14">
                <div className="mt-[18px] flex items-center justify-center gap-[10px] min-xl:mt-[30px]">
                    <Link to={detailsUrl} className={s.learnMore}>
                        {t("landing.learn-more")}
                    </Link>
                </div>
            </div>
        </div>
    );
}
