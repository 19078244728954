import Row from "react-bootstrap/Row";
import s from "./styles.module.css";

export default function Delivery() {
    return (
        <>
            <Row>
                <h2 className={s.title}>მიწოდების პირობები</h2>
            </Row>
            <Row>
                <ul className={s.unorderedList}>
                    <li className={s.listItem}>
                        ჩვენი საწყობიდან მომხმარებლის მიერ გატანა (თბილისი) -
                        უფასო
                    </li>
                    <li className={s.listItem}>თბილისში მიწოდება - 5 ლარი</li>
                    <li className={s.listItem}>
                        რეგიონებში მიწოდება - 10 ლარი
                    </li>
                </ul>
            </Row>
        </>
    );
}
