import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import s from "./styles.module.css";
import Showcase from "../../components/Showcase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function Home() {
    const { t } = useTranslation();
    const { lang } = useParams();

    return (
        <>
            <Row>
                <h2 className={s.title}>{t("landing.eufy-georgia")}</h2>
                <p className={`${s.subTitleQuote} mt-3`}>
                    {t("landing.slogan")}
                </p>
            </Row>
            <Row>
                <Col md={0} lg={6} className={s.showcaseContainer}>
                    <Showcase
                        backdrop
                        title={"eufy IndoorCam E220"}
                        description={t("landing.indoor-description")}
                        imageUrl={"/images/eufy-indoorcam-e220.webp"}
                        detailsUrl={`${lang ? `/${lang}` : ""}/IndoorCam-E220`}
                    />
                </Col>
                <Col md={0} lg={6} className={s.showcaseContainer}>
                    <Showcase
                        backdrop
                        title={"eufy SoloCam S220"}
                        description={t("landing.outdoor-description")}
                        imageUrl={"/images/eufy-solocam-s220.webp"}
                        detailsUrl={`${lang ? `/${lang}` : ""}/SoloCam-S220`}
                    />
                </Col>
            </Row>
            {/* <Row>
                    Some testimonials (რეკომენდატორები) https://testimonial.to/
                </Row> */}
        </>
    );
}
